import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import ImageCover from "../components/image-cover"
import LastRealisation from "../components/last-realisation"
import SEO from "../components/seo"
import RealisationSolution from "../components/realisation-solutions"

class Design extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Design" keywords={[
          "le marketing numérique","stratégie digitale","stratégie de marketing digital","formation marketing digitale","marketing et communication digitale","online marketing","stratégie de communication digitale","strategie digitale","content marketing","communication digitale","agence digitale","le marketing digitale","web marketing et communication digitale","marketing numérique","formation dans le digital","transformation digitale","strategie marketing digitale","formation web marketing","expérience client digitale","emploi digital"
        ]} />
        <main id="wall">
          
          { /* <section className="scroll-section"> */}
            <VideoCover
              title="L'agence digitale"
              videoUrl="/video/test.mp4" 
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover 
              title="UX / UI Design"
              content="De quelle manière organiser l’information pour rendre votre site le plus intuitif possible ? Véritable lien entre l’humain et la machine, l’UX/UI design apporte à l’internaute un environnement agréable, fluide et rassurant grâce à des solutions efficaces qui prennent en compte ses attentes et ses besoins. Objectif : faciliter la vie de l’utilisateur pour l’amener à concrétiser son action."
              classDiv="hero--uiux"
              btn={{
                action: "/modern-contact?from=ux-ui-design",
                value: "DEMANDER UN DEVIS"
              }}
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Webdesign"
              content="Nos webdesigners sont là pour faire de votre site internet un outil de communication percutant qui vous distingue de vos concurrents. Nous structurons l’architecture globale de votre site, son arborescence et sa navigation et vous proposons une charte graphique personnalisée, adaptée à vos besoins. Du desktop au mobile, en passant par la tablette, nous veillons à l’accessibilité et à la fonctionnalité du site à partir de tous les écrans."
              classDiv="hero--webdesign"
              btn={{
                action: "/modern-contact?from=Webdesign",
                value: "DEMANDER UN DEVIS"
              }}
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Animation de bannières et rich media"
              content="Incitez les internautes à interagir avec votre contenu grâce à des annonces dotées de fonctionnalités avancées : vidéo, son ou autre animation, les choix sont multiples !"
              classDiv="hero--richmedia"
              btn={{
                action: "/modern-contact?from=Rich-media-bannières",
                value: "DEMANDER UN DEVIS"
              }}
            />
          {/* </section> */}

          <LastRealisation />
          
          <RealisationSolution />
          
        </main>
      </Layout>
    
  )

  constructor(props) {
    super(props);
    this.state = {
      wall: null
    }
  }

  componentDidMount = () => {
    // helpers.loadCSS(cssStyle, "#pagepilling")
  }

  componentWillUnmount = () => {
    // helpers.unloadCSS("#pagepilling")
  }

}

export default Design
